import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useEffect, useState } from "react";
import axiosPrivateApi from "../../../config/Private";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 690,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const ConsentDialogue = ({ open, handleClose, consent }) => {
  // const { consent, signature } = consent;
  console.log("consent", consent);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <p className="closeButon" onClick={handleClose}>
          <HighlightOffIcon />
        </p>
        {/* <Typography id="modal-modal-title" variant="h6" component="h2">
          Text in a modal
        </Typography> */}
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div className="custom-modal">
            <div dangerouslySetInnerHTML={{ __html: consent?.consent }} />
            <img width={200} height={50} src={consent?.signature} />
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ConsentDialogue;
