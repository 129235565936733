import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";

import NotificationList from "./list/NotificationList";
import PaginationSection from "../../components/pagination/pagination";
//import DatePicker from "@mui/lab/DatePicker";
import "./Notification.css";

const Notification = () => {
  var defaultInput = {input:{ search:"", limit: 10, platform: '', status:'',fromDate:'',toDate:'',isSubmited:true }};

  const [show, setShow] = useState("");
  const [status, setStatus] = useState("");
  const [platform, setPlatform] = useState("");
  const [inputValues, setInputValues] = useState(defaultInput);

  const [dateMsg, setDateMsg] = useState("");

  const [filterParams, setFilterParam] = useState("");

  const handleChange = (e) => {

    let input = inputValues.input;
    input[e.target.name] = e.target.value;
    input['isSubmited'] = false;
    setInputValues((prev)=>({...prev,input:input}));
    if (e.target.name == "limit") {
      setShow(e.target.value);
    } else if (e.target.name == "platform") {
      setPlatform(e.target.value);
    } else if (e.target.name == "status") {
      setStatus(e.target.value);
    }
  };

  const handleCallback = (childData)=>{
      //setisSubmited(true);
      setFilterParam(childData);
      //setSetOffset(childData);
  };

  const handleSubmit = (e)=>{
    e.preventDefault();
    setDateMsg("");
    if (inputValues.input.fromdate > inputValues.input.toDate ) {
      setDateMsg("To date can not be smaller than From date");
    }
    setInputValues((prev)=>({...prev,input:{...prev.input,isSubmited:true} }));
  }

  const reset = ()=>{
    setInputValues(defaultInput);
  }

  return (
    <div className="notification-wrap">
      <div className="breadcrumb-wrap notification-btn">
        <Link to="/notification/add">
          <Button variant="contained">Add notification</Button>
        </Link>
      </div>
      <div className="filter-area">

        <form onSubmit={handleSubmit} >
        <div className="filter-top-content">
          <div className="search-box">
            <TextField
              placeholder="Search through title or description"
              type="text"
              name="search"
              onChange={handleChange}
              value={inputValues.input.search}
            />
          </div>

        </div>
        <div className="btn-area">
          <Button variant="contained" type='submit'>Apply</Button>
          <div className="reset-btn">
            <Button variant="outlined" onClick={reset} color="error">
              Reset
            </Button>
          </div>
        </div>
      </form>

      </div>
      <div className="main-section-wrap">
        <NotificationList data={inputValues} params={filterParams} page="notification" />
        <PaginationSection  parentCallback={handleCallback}  page="notification" />
      </div>
    </div>
  );
};

export default Notification;
