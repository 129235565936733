import React, { useState, useEffect } from "react";
import "../../assets/css/dashboard.css";
import ActiveUser from "../../assets/images/dashboard/active-user.png";
import BlockUser from "../../assets/images/dashboard/block-user.png";
import privateApi from "../../config/Private";
import Village from "../../assets/images/dashboard/village.svg";
import { Link } from "react-router-dom";

function Dashboard() {
  const [userCount, setUserCount] = useState({
    total: 0,
    active: 0,
    inActive: 0,
    villagers: 0,
  });

  useEffect(() => {
    privateApi
      .get("/dashboard")
      .then((response) => {
        if (response.data.statusCode === 200) {
          setUserCount(response.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const style={
    "text-decoration": "none"
  }

  return (
    <div className="dashboard-wrapper">
      {/* <h3 className="section-title">Dashboard Management</h3> */}
      <div className="card-section dashboard-card">
        <div className="card-row">


          <div className="card-col">
            <Link to='/users' style={style}>
            <div className="card-box">
              <div className="card-box-inner">
                <div className="card-left">
                  <h2 className="card-info">{userCount.total}</h2>
                </div>
                <div className="card-right">
                  <div className="card-image">
                    <figure>
                      <img src={ActiveUser} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="card-description">
                <p>Total Users</p>
              </div>
            </div>
            </Link>
          </div>


          <div className="card-col">
            <Link to='/users' style={style}>
            <div className="card-box">
              <div className="card-box-inner">
                <div className="card-left">
                  <h2 className="card-info">{userCount.active}</h2>
                </div>
                <div className="card-right">
                  <div className="card-image">
                    <figure>
                      <img src={ActiveUser} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="card-description">
                <p>Active Users</p>
              </div>
            </div>
           </Link>
          </div>


          <div className="card-col">
            <Link to='/users' style={style}>
            <div className="card-box">
              <div className="card-box-inner">
                <div className="card-left">
                  <h2 className="card-info">{ userCount.inActive }</h2>
                </div>
                <div className="card-right">
                  <div className="card-image">
                    <figure>
                      <img src={BlockUser} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="card-description">
                <p>Blocked Users</p>
              </div>
            </div>
            </Link>
          </div>


          <div className="card-col">
          <Link to='/villagers' style={style}>
            <div className="card-box">
              <div className="card-box-inner">
                <div className="card-left">
                  <h2 className="card-info">{ userCount.villagers }</h2>
                </div>
                <div className="card-right">
                  <div className="card-image village">
                    <figure>
                      <img src={Village} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="card-description">
                <p>Total Villagers</p>
              </div>
            </div>
              </Link>
          </div>
        </div>


        <br></br>
        <div className="card-row">
        <div className="card-col">
        <Link to='/villagers' style={style}>
          <div className="card-box">
            <div className="card-box-inner">
              <div className="card-left">
                <h2 className="card-info">{ userCount.parent }</h2>
              </div>
              <div className="card-right">
                <div className="card-image village">
                  <figure>
                    <img src={ActiveUser} alt="" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="card-description">
              <p>Total Parents</p>
            </div>
          </div>
         </Link>
        </div>
        </div>

      </div>
    </div>
  );
}

export default Dashboard;
