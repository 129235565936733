import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;
const instance = axios.create({
    baseURL: BASE_URL
});

instance.interceptors.request.use( config=>{
  config.headers = {
    ...config.headers,
    authorization:"Basic bWVlcmthdDpyY2M="
  }
  return config;
},err=>Promise.reject(err));

export default instance;
