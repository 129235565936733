
const reducer  = (state = false ,  action: any) =>{
  switch (action.type) {
    case "PROFILE_DETAILS":
      return {...state ,...action.payload }
      break;
    default:
    return state;
  }
}
export default reducer;
