import React, { useState, useEffect } from "react";
import uplodIcon from "../../../assets/images/profile/upload-icon.png";
import "./UsersDetail.css";
import Button from "@mui/material/Button";
import BlockPopup from "../../../components/blockpopup/blockpopup";
import UnblockPopup from "../../../components/unblockpopup/unblockpopup";
import EditProfileDialog from "../../../components/profile/edit-profile-popup/edit-popup";
import Userimg from "../../../assets/images/profile/profile-img.png";
import axiosPrivateApi from "../../../config/Private";
import ArticleIcon from "@mui/icons-material/Article";
import {
  getStatusText,
  getDateInFormat,
  getThemeColor,
  getUserType,
} from "../../../utils/Profile";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import VillageTableList from "./list";
import { Link, useParams } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ConsentDialogue from "./ConsentDialogue";

const UsersDetail = (props) => {
  const queryParam = useParams();
  const [value, setValue] = useState("");
  const [changeStatus, setUpdateStatus] = useState(false);
  const [profile, setProfileData] = useState({});
  let [isLoading, setIsLoading] = useState(false);

  // console.log("profile", profile);

  const [openBlock, setBlockOpen] = useState(false);
  const handleBlockClickOpen = () => {
    setBlockOpen(true);
  };

  const [openUnblock, setUnblockOpen] = useState(false);
  const handleUnblockClickOpen = () => {
    setUnblockOpen(true);
  };

  const [open, setOpen] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  const [consentId, setConsentId] = useState([]);

  const updateStatus = (childData) => {
    setUpdateStatus(childData);
  };

  // handle consent
  const handleConsent = (row) => {
    console.log("hello from consent", row);
    setIsOpen(true);
    async function getProfileDetails() {
      axiosPrivateApi
        .get(
          `/user/view-villager-consent?villagerMemberId=${row?._id}&villageId=${row?.villageId?._id}`
        )
        .then((res) => {
          setConsentId(res.data.responseData);
          setIsLoading(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getProfileDetails();
  };

  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    async function getProfileDetails() {
      axiosPrivateApi
        .get("/user/detail?userId=" + queryParam.id)
        .then((res) => {
          setProfileData(res.data.responseData.userDetail);
          setIsLoading(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getProfileDetails();
  }, [changeStatus, value]);

  console.log(profile);

  return (
    <div className="user-profile-wrap">
      <div className="breadcrumb-wrap">
        <div className="breadcrumb-left">
          <Link to="/users">
            <h2 className="sub-title">User Management</h2>
          </Link>
          <div className="back-arrow">
            <KeyboardArrowRightIcon />
          </div>
          <h2 className="sub-title">User Details</h2>
        </div>
        <div className="breadcrumb-right">
          {/* <div className="edit-btn">
              <Button variant="contained" onClick={handleClickOpen}>
                Edit
              </Button>
            </div> */}

          {profile.IsActive == 1 && (
            <div className="block-btn">
              {" "}
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleBlockClickOpen()}
              >
                {" "}
                Block
              </Button>
            </div>
          )}

          {profile.IsActive == 2 && (
            <div className="block-btn">
              {" "}
              <Button
                variant="outlined"
                color="success"
                onClick={() => handleUnblockClickOpen()}
              >
                {" "}
                Unblock
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="profile-detail-inner">
        <div className="user-profile-info">
          <div className="upload-wrap">
            <div className="upload-file" style={{ display: "none" }}>
              <input type="file" name="profileImage" />
              <div className="upload-icon">
                <figure>
                  <img src={uplodIcon} alt="Upload Icon" />
                </figure>
                <p>Upload Image</p>
              </div>
            </div>
            <div className="upload-image-2">
              <figure>
                <img
                  src={profile?.avatarUrl ? profile?.avatarUrl : Userimg}
                  alt="User Icon"
                />
              </figure>
              {/* <div className="close-icon">
                <CloseIcon />
              </div> */}
            </div>
          </div>

          <div className="col-right">
            <div className="user-row">
              <div className="user-col">
                <div className="user-info">
                  <label>Name</label>
                  <p>{profile?.name}</p>
                </div>
              </div>
              <div className="user-col">
                <div className="user-info">
                  <label>Email ID</label>
                  <p>{profile?.email}</p>
                </div>
              </div>
              <div className="user-col">
                <div className="user-info">
                  <label>Status</label>
                  <p>
                    {" "}
                    {isLoading &&
                      getStatusText(profile?.["authId"]["status"])}{" "}
                  </p>
                </div>
              </div>
              <div className="user-col">
                <div className="user-info">
                  <label>User Type</label>
                  <p>{getUserType(profile?.userType)}</p>
                </div>
              </div>
              <div className="user-col">
                <div className="user-info">
                  <label>Registered On</label>
                  <p>{getDateInFormat(profile?.createdAt)}</p>
                </div>
              </div>

              <div className="user-col-100">
                <div className="user-info">
                  <label>Bio</label>
                  <p>{profile?.bio}</p>
                </div>
              </div>
              <div className="subscription-paid">
                <div className="tile">
                  <h2>User Type</h2>
                  <span>{profile?.isFreeSubscription ? "Free" : "Paid"}</span>
                </div>
                <div className="tile">
                  <h2>Licenses Purchased</h2>
                  <span>
                    {profile?.totalLicense + profile?.villageDetail?.length}
                  </span>
                </div>
                <div className="tile">
                  <h2>Licenses Used</h2>
                  <span>{profile?.villageDetail?.length}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {profile.userType == 1 ? (
        <>
          <div className="users-heading">
            <h2 className="sub-title">Villages</h2>
            <div className="border"></div>
          </div>
          <div className="profile-detail-inner mb-50 p-0">
            <div className="profile-header">
              <h2 className="sub-title">Village Created</h2>
            </div>

            <div className="alldata">
              {profile?.villageDetail?.length ? (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <>
                      <TableHead>
                        <TableRow>
                          {/* <TableCell>S.No.</TableCell> */}
                          <TableCell>Village Name</TableCell>
                          <TableCell>Village Colour</TableCell>
                          <TableCell>No. of Village Members</TableCell>
                          <TableCell>Village Creation Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {profile?.villageDetail?.map((row) => (
                          <TableRow key={row?.authId?._id}>
                            <TableCell>
                              <Link
                                to={`/village/detail/${row?._id}`}
                                style={{
                                  color: "inherit",
                                  // textDecoration: "none",
                                }}
                              >
                                {row?.title}
                              </Link>
                            </TableCell>
                            <TableCell>{getThemeColor(row?.theme)}</TableCell>
                            <TableCell>{row?.memberCount}</TableCell>
                            <TableCell>
                              {getDateInFormat(row?.createdAt)}
                            </TableCell>
                          </TableRow>
                        ))}
                        {/* {props?.villagerList?.length == 0 && (
                          <TableRow key={1} align="center">
                            <TableCell colSpan={8}>
                              No Villager found!
                            </TableCell>
                          </TableRow>
                        )} */}
                      </TableBody>
                    </>
                  </Table>
                </TableContainer>
              ) : (
                <p
                  className=""
                  align="center"
                  style={{ padding: "10px", color: "#c00" }}
                >
                  No data found!
                </p>
              )}
            </div>
          </div>

          <div className="profile-detail-inner mb-0 p-0 alldata">
            <div className="profile-header">
              <h2 className="sub-title">Villages Joined</h2>
            </div>

            <div className="alldata">
              {profile?.memberVillageList.length ? (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell>S.No.</TableCell> */}
                        <TableCell>Village Name</TableCell>
                        <TableCell>Village Owner</TableCell>
                        <TableCell>No. of Village Members</TableCell>
                        <TableCell>Village Creation Date</TableCell>
                        <TableCell>Village left Date</TableCell>
                        <TableCell>Consent Form</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow></TableRow>
                      {profile?.memberVillageList?.map((row) => (
                        <TableRow key={row?._id}>
                          {/* <TableCell>{++sr}</TableCell> */}
                          <TableCell>
                            <Link
                              to={`/village/detail/${row?.villageId?._id}`}
                              style={{
                                color: "inherit",
                                // textDecoration: "none",
                              }}
                            >
                              {row?.villageId?.title}
                            </Link>
                            {/* {row?.villageId?.title} */}
                          </TableCell>
                          <TableCell>{row?.ownerAuthId?.name}</TableCell>
                          <TableCell>{row?.villageId?.memberCount}</TableCell>
                          <TableCell>
                            {getDateInFormat(row?.joinDate)}
                          </TableCell>
                          <TableCell>
                            {getDateInFormat(row?.leaveDate)}
                          </TableCell>
                          <TableCell>
                            <span
                              className="consent"
                              onClick={() => handleConsent(row)}
                            >
                              <ArticleIcon />
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                      {/* {profile?.villagerList?.length == 0 && (
                        <TableRow key={1} align="center">
                          <TableCell colSpan={8}>No Villager found!</TableCell>
                        </TableRow>
                      )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <p
                  className=""
                  align="center"
                  style={{ padding: "10px", color: "#c00" }}
                >
                  No data found!
                </p>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {profile.userType == 2 ? (
        <div className="main-section-wrap">
          <div className="table-heading">
            <h2 className="sub-title">Villages</h2>
          </div>
          {isLoading && (
            <VillageTableList villagerList={profile?.memberVillageList} />
          )}
        </div>
      ) : (
        ""
      )}

      <div className="block-alert">
        <BlockPopup
          profileData={profile}
          parentCallback={updateStatus}
          open={openBlock}
          setOpen={setBlockOpen}
        />
      </div>
      <div className="unblock-alert">
        <UnblockPopup
          profileData={profile}
          open={openUnblock}
          parentCallback={updateStatus}
          setOpen={setUnblockOpen}
        />
      </div>
      <div className="edit-profile-popup">
        <EditProfileDialog open={open} setOpen={setOpen} />
      </div>
      <ConsentDialogue
        consent={consentId}
        open={isOpen}
        handleClose={closeModal}
      />
    </div>
  );
};

export default UsersDetail;
