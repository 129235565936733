const intialState = {
  "per_page":10
}
const reducer  = (state=intialState ,  action: any) =>{
  switch (action.type) {
    case "PAGINATION":
        if (action.payload.page == "user") {
            state['per_page'] = action.payload.per_page;
        }
       return {...state,...action.payload,per_page:state['per_page'],total:action.payload.total } ;
      break;
    case "NEXT_PAGINATION":
    console.log("NEXT_PAGINATION",{...action.payload,...state });
      return {...action.payload,...state } ;
      break;
    case "VILLAGER_PAGINATION":
        if (action.payload.page == "villager") {
            state['per_page'] = action.payload.per_page;
        }
        return {...state,...action.payload,per_page:state['per_page'],total:action.payload.total };
      break;
    default:
    return state;
  }
}
export default reducer;
