import React,{ useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Content.css";
import privateApi from "../../config/Private";
import CircularProgress from "@mui/material/CircularProgress";


function CmsTab() {

  const [list, setList] = useState({res:[]});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    privateApi
      .get("/cms/cms-list")
      .then((response) => {
        setList({res:response.data.responseData.cms});
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, []);


  return (
  <>
   { loader ? <div
         className="content-loader"
         style={{
           position: "absolute",
           top: "50%",
           left: "50%",
           transform: "translate(-50%, -50%)",
         }}
       >
         <CircularProgress size={100} />
       </div> : <div className="content-tab-wrap">
      { list.res.map((row) => (

        <div className="content-tabs" key={row._id}>
          <div className="tab1">
            <h2 className="sub-title"><a href={process.env.REACT_APP_CLIENT_APP_URL+"/cms/"+row.type} target="_blank" key={row._id}>{row.title}</a></h2> <span className="edittext"><Link to={"cms/"+row.type} key={row._id}>Edit</Link></span>
          </div>
        </div>
       )) }
      {!list.res.length ? <p>No Data found</p> :"" }
    </div>}
    </>
  );
}

export default CmsTab;
