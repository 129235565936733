import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const instanceNew = axios.create({
  baseURL: BASE_URL,
});
//let remember_me = window.localStorage.getItem('remember_me');
instanceNew.interceptors.request.use(
  (config) => {
    let useraccessToken = window.localStorage.getItem("accessToken");
    config.headers = {
      ...config.headers,
      "X-AUTH-TOKEN": useraccessToken,
      authorization: "Basic bWVlcmthdDpyY2M=",
    };
    return config;
  },
  (err) => Promise.reject(err)
);

instanceNew.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    var statusCode = [440];
    if (statusCode.includes(err.response.status)) {
      window.localStorage.clear();
      localStorage.setItem("authStatus", 0);
      window.location.href = "/login";
    }

    return Promise.reject(err);
  }
);

export default instanceNew;
