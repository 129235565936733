import persistStore from "./redux/store";
import { Provider } from "react-redux";
import Webpage from "./Route/Index";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <Provider store={persistStore.store}>
      <PersistGate loading={null} persistor={persistStore.persistor}>
        <div className="App">
          <Webpage />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
