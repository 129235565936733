import React, { useState } from "react";
import "../../assets/css/login.css";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import axiosPublic from "../../config/public";
import { useDispatch } from "react-redux";
import Logo from "../../assets/images/login/Logo.svg";

function Login() {
  var states = {
    input: {},
    errors: {},
  };
  const [state, setValue] = useState(states);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setShowError] = useState("");

  const [checked, setChecked] = useState(true);

  const [loader, setLoader] = useState(false);
  const history = useHistory();
  let dispatch = useDispatch();


  const handleChange = (e) => {
    let input = state.input;
    input[e.target.name] = e.target.value;
    setValue((prevState) => ({ ...prevState, input: input }));
  };

  const handleChecked = (e) => {
    setChecked(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(e)) {
      loginuser(state.input);
    }
  };

  function loginuser(credentials) {
    setLoader(true);
    axiosPublic
      .post("/login", credentials)
      .then((response) => {
        setShowError("");
        setLoader(false);
        let userInfo = response.data.responseData;
        window.localStorage.clear();
        window.localStorage.setItem("authStatus", 1);
        window.localStorage.setItem("accessToken", userInfo.access_token);
        window.localStorage.setItem("userId", userInfo.user._id);
        window.localStorage.setItem("email", userInfo.user.email);
        window.localStorage.setItem("name", userInfo.user.name);
        window.localStorage.setItem("picture", userInfo.user.avatarUrl);
        window.localStorage.setItem("userType", userInfo.user.userType);
        if (checked) {
          window.localStorage.setItem("remember_me", 1);
        } else {
          window.localStorage.setItem("remember_me", 0);
        }
        dispatch({ type: "PROFILE_DETAILS", payload: userInfo.user });
        history.push("/dashboard");
      })
      .catch((err) => {
        setLoader(false);
        setShowError(err.response.data.responseMsg);
      });
  }

  const validate = (e) => {
    let errors = {};
    errors["email"] = "";
    errors["password"] = "";
    let isValid = true;
    if (state.input.email == undefined || state.input.email == "") {
      isValid = false;
      errors["email"] = "Please enter your email address.";
    }
    if (state.input.password == undefined || state.input.password == "") {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }

    setValue((prevState) => ({ ...prevState, errors: errors }));
    return isValid;
  };

  const handleTogglePassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  return (
    <div className="account-wrapper">
      <div className="account-inner">
        <div className="login-section">
          <div className="logo">
            <Link to="/">
              <figure>
                <img src={Logo} alt="Logo" />
              </figure>
            </Link>
          </div>
          <h1 className="page-title">Welcome to MEERKAT VILLAGE</h1>
          <div className="form-wrapper">
            <form onSubmit={handleSubmit}>
              <div className="input-row">
                <label>Email Address</label>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  onChange={(e) => handleChange(e)}
                  value={states.input.email}
                />
                {state.errors.email && (
                  <span className="error-msg">{state.errors.email}</span>
                )}
              </div>
              <div className="password-row">
                <div className="form-group">
                  <label>Password</label>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    password="password"
                    placeholder="Enter Your Password"
                    onChange={(e) => handleChange(e)}
                    value={states.input.password}
                  />
                  <div className="eye-icon">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                  {state.errors.password && (
                    <span className="error-msg">{state.errors.password}</span>
                  )}
                </div>
              </div>

              <div className="forgot-link">
                <Link to="/forgot-password">Forgot Password?</Link>
                {error && <span className="error-msg">{error}</span>}
              </div>
              <div className="remember-me">
                <Checkbox
                  name="remember_me"
                  checked={checked}
                  onChange={(e) => handleChecked(e)}
                  value={state.input.remember_me}
                />
                <p>Remember me</p>
              </div>
              <div className="btn-wrap login-btn">
                <Button
                  className="login"
                  variant="contained"
                  type="submit"
                  disabled={loader}
                >
                  {loader && <CircularProgress size={22} />}
                  Login
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
