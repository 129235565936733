import React, { useState, useEffect } from "react";
import "../../assets/css/login.css";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import { useHistory, Link, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import axiosPublic from "../../config/public";
import Logo from "../../assets/images/login/Logo.svg";



function ForgotResetPassword() {
  const params = useParams();
  let token =  params.token;
  const [msg, setErrMsg] = useState("");

  var states = {
    input: {},
    errors: {},
  };
  const [state, setValue] = useState(states);
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tokenCheck, setToken] = useState(true);

  const [error, setShowError] = useState("");
  const [success, setSuccessError] = useState("");

  //const location = useLocation();

  const history = useHistory();

  useEffect(() => {
    axiosPublic
      .post("/validate-token", { token: token })
      .then((response) => {
        setToken(true);
      })
      .catch((err) => {
        setToken(false);
        setErrMsg(err.response.data.message);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(e)) {
      let credentials = {
        token: token,
        password: state.input.password,
      };
      setLoader(true);
      axiosPublic
        .post("/reset-password", credentials)
        .then((response) => {
          setLoader(false);
          setValue((prevState) => ({ ...prevState, errors: {} }));
          setShowError("");
          setSuccessError("Password Successfully updated.");
          history.push("/login");
        })
        .catch((err) => {
          setLoader(false);
          setShowError(err.response.data.message);
        });
    } else {
      console.log("");
    }
  };

  const handleChange = (e) => {
    let input = state.input;
    input[e.target.name] = e.target.value;
    setValue((prevState) => ({ ...prevState, input: input }));
  };

  const validate = (e) => {
    let errors = {};
    errors["password"] = "";
    errors["cpassword"] = "";
    let isValid = true;
    if (state.input.password == undefined || state.input.password == "") {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }
    if (state.input.cpassword == undefined || state.input.cpassword == "") {
      isValid = false;
      errors["cpassword"] = "Please enter your confirm password.";
    }
    if (state.input.password != state.input.cpassword) {
      isValid = false;
      errors["passwordNotMatch"] =
        "Your confirm password does not match with new password";
    }

    if (state.input.password != null) {
      if (state.input.password.length < 8) {
        isValid = false;
        errors["passwordLengthErr"] = "Password must be atleast 8 digits long.";
      }
    }

    setValue((prevState) => ({ ...prevState, errors: errors }));
    return isValid;
  };

  const handleTogglePassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };
  const handleTogglecPassword = () => {
    setShowcPassword((showcPassword) => !showcPassword);
  };

  return (
    <div className="account-wrapper">
      <div className="account-inner">
        <div className="login-section">
          <div className="logo">
            <Link to="/">
              <figure>
                <img src={Logo} alt="Logo" />
              </figure>
            </Link>
          </div>
          <h1 className="page-title">Reset Password</h1>
          <div className="form-wrapper">
            <form onSubmit={handleSubmit}>
              <div className="password-row">
                <div className="form-group">
                  <label>*New Password</label>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    onChange={handleChange}
                    value={states.input.password}
                    placeholder="Enter Your New Password"
                  />
                  <div className="eye-icon">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                  {state.errors.password && (
                    <span className="error-msg">{state.errors.password}</span>
                  )}
                </div>
              </div>
              <div className="password-row top-spacing">
                <div className="form-group">
                  <label>*Confirm New Password</label>
                  <TextField
                    type={showcPassword ? "text" : "password"}
                    id="cpassword"
                    name="cpassword"
                    onChange={handleChange}
                    value={states.input.cpassword}
                    placeholder="Confirm Your New Password"
                  />
                  <div className="eye-icon">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglecPassword}
                    >
                      {showcPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                  {state.errors.password && (
                    <span className="error-msg">{state.errors.password}</span>
                  )}
                </div>
              </div>
              {state.errors.passwordNotMatch ? (
                <span className="error-msg">
                  {state.errors.passwordNotMatch}
                </span>
              ) : (
                ""
              )}
              {error ? <span className="error-msg">{error}</span> : ""}
              {success ? <span className="success-msg">{success}</span> : ""}
              <br />
              {state.errors.passwordLengthErr ? (
                <span className="error-msg">
                  {state.errors.passwordLengthErr}
                </span>
              ) : (
                ""
              )}
              {msg ? <span className="error-msg">{msg}</span> : ""}

              {tokenCheck ? (
                <div className="btn-wrap reset-btn">
                  <Button variant="contained" type="submit" disabled={loader}>
                    {loader && <CircularProgress size={24} />}Set new password
                  </Button>
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotResetPassword;
