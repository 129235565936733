
const reducer  = (state = false ,  action: any) =>{
  switch (action.type) {
    case "SIDEBAR_COLLAPSE":
      return {...action.payload }
      break;
    default:
    return state;
  }
}
export default reducer;
