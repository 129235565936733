import React, { useState, useEffect } from "react";
import "./Detail.css";

import axiosPrivateApi from "../../../config/Private";
import { getDateInFormat, getThemeColor } from "../../../utils/Profile";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import VillageDetailTableList from "./list";
import { Link } from "react-router-dom";

const VillageDetail = (props) => {
  const [villager, setVillagerData] = useState({});

  let [isLoading, setIsLoading] = useState(false);

  const id = props.match.params.id;

  useEffect(() => {
    async function getList() {
      axiosPrivateApi
        .get("/village/detail?villageId=" + id)
        .then((res) => {
          setVillagerData(res.data.responseData.village);
          setIsLoading(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getList();
  }, []);

  return (
    <div className="user-profile-wrap">
      <div className="breadcrumb-wrap">
        <div className="breadcrumb-left">
          <Link to="/villagers">
            <h2 className="sub-title">Village Management</h2>
          </Link>
          <div className="back-arrow">
            <KeyboardArrowRightIcon />
          </div>
          <h2 className="sub-title">Village Details</h2>
        </div>
      </div>
      <div className="profile-detail-inner mb-0 p-0">
        <div className="profile-header">
          <h2 className="sub-title">Village Details</h2>
        </div>
        <div className="village-details">
          <div className="user-row">
            <div className="user-col mb-0">
              <div className="user-info">
                <label>Village Name</label>
                <p>{villager.title}</p>
              </div>
            </div>
            <div className="user-col mb-0">
              <div className="user-info">
                <label>No. of village members</label>
                <p>{villager.memberCount}</p>
              </div>
            </div>
            <div className="user-col mb-0">
              <div className="user-info">
                <label>Village Colour</label>
                <p>{getThemeColor(villager.theme)}</p>
              </div>
            </div>
            <div className="user-col mb-0">
              <div className="user-info">
                <label>Village Creation Date</label>
                <p>{getDateInFormat(villager.createdAt)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-section-wrap">
        <div className="table-heading">
          <h2 className="sub-title">Village Members</h2>
        </div>
        {isLoading && (
          <VillageDetailTableList villageMember={villager.villageMember} />
        )}
      </div>
    </div>
  );
};

export default VillageDetail;
