import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import FormDoc from "../../../assets/images/users-detail/form-doc.svg";
import "./UsersDetail.css";
import { getDateInFormat } from "../../../utils/Profile";

const VillageTableList = (props) => {
  var sr = 0;
  return (
    <div className="table-wrapper village-list">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>S.No.</TableCell>
              <TableCell>Village Name</TableCell>
              <TableCell>Village Owner</TableCell>
              <TableCell>No. of Village Members</TableCell>
              <TableCell>Village joined Date</TableCell>
              <TableCell>Village left Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.villagerList.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{++sr}</TableCell>
                <TableCell>{row.villageId.title}</TableCell>
                <TableCell>{row.ownerAuthId.name}</TableCell>
                <TableCell>{row.villageId.memberCount}</TableCell>
                <TableCell>{getDateInFormat(row.joinDate)}</TableCell>
                <TableCell>{getDateInFormat(row.leaveDate)}</TableCell>
              </TableRow>
            ))}
            {props.villagerList.length == 0 && (
              <TableRow key={1} align="center">
                <TableCell colSpan={8}>No Villager found!</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default VillageTableList;
