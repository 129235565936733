import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import "./list.css";
import axiosPrivateApi from "../../../config/Private";
import { getDateInFormat } from "../../../utils/Profile";
import { useDispatch } from "react-redux";
import Loader from "../../../components/loader/loader";

const VillageUserTableList = (props) => {
  const [userListing, setUserList] = useState([]);

  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  console.log("props.offset", props.data);
  var filterInput = props.data.input;
  var filterParam = {
    limit: filterInput.limit,
    offset: props.offset ? (props.offset - 1) * 10 : props.offset,
  };

  if (filterInput.search) {
    filterParam.search = filterInput.search;
  }
  filterParam.sortBy = "_id";
  filterParam.sortOrder = "desc";

  var lastOffset = props.offset;

  var srNo =
    0 + (lastOffset ? (lastOffset - 1) * props.data.input.limit : lastOffset);

  useEffect(() => {
    if (props.data.isSubmited) {
      async function getVillagerList() {
        setProcessing(true);
        axiosPrivateApi
          .post("/village/list", filterParam)
          .then((res) => {
            setProcessing(false);
            if (res.data.responseCode == 200) {
              setUserList(res.data.responseData.list);
              dispatch({
                type: "VILLAGER_PAGINATION",
                payload: { total: res.data.responseData.count, per_page: 10 },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      getVillagerList();
    }
  }, [props]);

  return (
    <div className="user-main-wrapper">
      <div className="table-wrapper vil-list">
        {processing && <Loader />}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>
                  <div className="sort-outer">
                    <span>Village Name</span>
                    {/*<ColumnSort />*/}
                  </div>
                </TableCell>
                <TableCell>Village Owner</TableCell>

                <TableCell>No of Village Members</TableCell>
                <TableCell>
                  <div className="sort-outer">
                    <span>Village Created date</span>
                    {/*<ColumnSort />*/}
                  </div>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userListing.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{++srNo}</TableCell>
                  <TableCell>
                    <div className="user-info-sec">
                      <Link to={"village/detail/" + row._id}>{row.title}</Link>
                    </div>
                  </TableCell>
                  <TableCell>{row.ownerAuthId.name}</TableCell>

                  <TableCell>{row.memberCount}</TableCell>
                  <TableCell>{getDateInFormat(row.createdAt)}</TableCell>
                  <TableCell>
                    <div className="action-wrap">
                      {
                        <Tooltip title="View">
                          <Link to={"village/detail/" + row._id}>
                            <IconButton>
                              <VisibilityIcon />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      }
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {userListing.length == 0 && (
                <TableRow>
                  <TableCell colSpan="7">No data found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default VillageUserTableList;
