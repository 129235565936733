import Login from "./Login";
function Logout() {
  window.localStorage.clear();
  localStorage.setItem("authStatus", 0);
  return (
    <>
      <Login />
    </>
  );
}
export default Logout;
