import { useState, useEffect } from "react";
import Button from "@mui/material/Button";

import "../../assets/css/profile.css";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EditProfileDialog from "../../components/profile/edit-profile-popup/edit-popup";
import SuccessfullPasswordPopup from "../../components/profile/successfull-password-popup/SuccessfulPassword";
import axiosPrivate from "../../config/Private";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";

const Profile = (props) => {
  const [open, setOpen] = useState(false);
  var states = {
    input: {},
    errors: {},
  };
  const [pass, setShowPassword] = useState({
    oldpass: false,
    pass: false,
    cpass: false,
  });
  const [profile, setProfileData] = useState({
    name: "",
    phone_number: "",
    email: "",
  });
  const [state, setValue] = useState(states);
  const [loader, setLoader] = useState(false);

  const [resMsg, setShowMsg] = useState({ msg: "", status: false });

  const [profileUpdated, setProfileUpdate] = useState(false);

  const [profileMsg, setProfileMsg] = useState({
    msg: "",
    status: false,
    imgLoader: false,
  });
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChange = (e) => {
    let input = state.input;
    input[e.target.name] = e.target.value;
    setValue((prevState) => ({ ...prevState, input: input }));
  };
  const handleTogglePassword = (e, v) => {
    setShowPassword({ [v]: !pass[v] });
  };
  const handleChangeSubmit = async (e) => {
    e.preventDefault();
    if (validate(e)) {
      setLoader(true);
      await axiosPrivate
        .put("/change-password", {
          currentPassword: state.input.oldpassword,
          newPassword: state.input.password,
          confirmPassword: state.input.cpassword,
        })
        .then((response) => {
          setLoader(false);
          setShowMsg({ msg: response.data.message, status: true });
        })
        .catch((err) => {
          setLoader(false);
          setShowMsg({ msg: err.response.data.message, status: false });
        });
    }
  };

  const handleCallback = (childData) => {
    setProfileUpdate(true);
  };

  if (profileUpdated) {
    setTimeout(() => {
      setProfileUpdate(false);
    }, 1500);
  }

  useEffect(() => {
    axiosPrivate
      .get("/profile")
      .then((response) => {
        //console.log(response.data.result);
        setProfileData(response.data.result);
        dispatch({ type: "PROFILE_DETAILS", payload: response.data.result });
        //setShowImg({ img: response.data.data.picture });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props]);

  const validate = (e) => {
    let errors = {};
    errors["oldpassword"] = "";
    errors["password"] = "";
    errors["cpassword"] = "";
    errors["passwordLengthErr"] = "";
    let isValid = true;

    if (state.input.oldpassword == undefined || state.input.oldpassword == "") {
      isValid = false;
      errors["oldpassword"] = "Please enter your old password password.";
    }

    if (state.input.password == undefined || state.input.password == "") {
      isValid = false;
      errors["password"] = "Please enter your new password.";
    }
    if (state.input.cpassword == undefined || state.input.cpassword == "") {
      isValid = false;
      errors["cpassword"] = "Please enter your confirm password.";
    }
    if (state.input.password != state.input.cpassword) {
      isValid = false;
      errors["passwordNotMatch"] =
        "Your confirm password does not match with New password";
    }
    if (state.input.password != null) {
      //
      console.log(state.input.password.length);
      if (state.input.password.length < 8) {
        isValid = false;
        errors["passwordLengthErr"] = "Password must be at least 8 character.";
      }
    }

    if (!isValid) {
      setShowMsg({ msg: "", status: false });
    }

    setValue((prevState) => ({ ...prevState, errors: errors }));
    return isValid;
  };

  const [passwordopen, setPasswordOpen] = useState(false);

  const handlePasswordClickOpen = () => {
    setPasswordOpen(true);
  };

  return (
    <div className="profile-wrap">
      <div className="profile-inner">
        <div className="breadcrumb-wrap">
          <div className="breadcrumb-left">
            <h2 className="sub-title">Profile Details</h2>
          </div>
          <div className="breadcrumb-right">
            <Button variant="contained" onClick={handleClickOpen}>
              Edit profile
            </Button>
          </div>
        </div>
        <div className="user-profile-detail">
          <div className="col-right pl">
            <div className="user-row">
              <div className="user-col33 ">
                <div className="user-info">
                  <label>Name</label>
                  <p>{profile.name ?? "N/A"}</p>
                </div>
              </div>
              <div className="user-col33">
                <div className="user-info">
                  <label>Email ID</label>
                  <p>{profile.email}</p>
                </div>
              </div>
              <div className="user-col33">
                <div className="user-info">
                  <label>Added On</label>
                  <p>6/14/2022</p>
                </div>
              </div>
              <div className="user-col33 mb-0">
                <div className="user-info">
                  <label>Status</label>
                  <p>Active</p>
                </div>
              </div>
              {profileUpdated ? (
                <span className="success-msg">
                  Profile successfully updated
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="change-password-wrap">
        <div className="breadcrumb-wrap">
          <h2 className="sub-title">Change Password</h2>
        </div>
        <form onSubmit={handleChangeSubmit}>
          <div className="row">
            <div className="password-col">
              <div className="form-content">
                <TextField
                  type={pass.oldpass ? "text" : "password"}
                  name="oldpassword"
                  label="*Current Password"
                  variant="outlined"
                  onChange={handleChange}
                  value={states.input.oldpassword}
                  placeholder="Enter Current Password"
                />
                <div className="eye-icon">
                  <IconButton
                    onClick={(e) => handleTogglePassword(e, "oldpass")}
                  >
                    {pass.oldpass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>
              </div>
              {state.errors.oldpassword ? (
                <span className="error-msg"> {state.errors.oldpassword} </span>
              ) : (
                ""
              )}
            </div>
            <div className="password-col">
              <div className="form-content">
                <TextField
                  type={pass.pass ? "text" : "password"}
                  name="password"
                  label="*New Password"
                  variant="outlined"
                  onChange={handleChange}
                  value={states.input.password}
                  placeholder="Enter New Password"
                />
                <div className="eye-icon">
                  <IconButton onClick={(e) => handleTogglePassword(e, "pass")}>
                    {pass.pass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>
              </div>
              {state.errors.password ? (
                <span className="error-msg"> {state.errors.password} </span>
              ) : (
                ""
              )}
            </div>
            <div className="password-col">
              <div className="form-content">
                <TextField
                  type={pass.cpass ? "text" : "password"}
                  name="cpassword"
                  label="*Confirm New Password"
                  variant="outlined"
                  value={states.input.cpassword}
                  onChange={handleChange}
                  placeholder="Enter Your New Password"
                />
                <div className="eye-icon">
                  <IconButton onClick={(e) => handleTogglePassword(e, "cpass")}>
                    {pass.cpass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>
              </div>
              {state.errors.cpassword ? (
                <span className="error-msg"> {state.errors.cpassword} </span>
              ) : (
                ""
              )}
              {state.errors.passwordNotMatch ? (
                <span className="error-msg">
                  {state.errors.passwordNotMatch}
                </span>
              ) : (
                ""
              )}
              {state.errors.passwordLengthErr ? (
                <span className="error-msg">
                  {state.errors.passwordLengthErr}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          {resMsg.msg ? (
            <span className={!resMsg.status ? "error-msg" : "success-msg"}>
              {" "}
              {resMsg.msg}{" "}
            </span>
          ) : (
            ""
          )}
          <div className="btn-group">
            <div className="save-btn" disabled={loader}>
              <Button variant="contained" type="submit">
                {loader && <CircularProgress size={24} />} Save
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className="edit-profile-popup">
        <EditProfileDialog
          open={open}
          profile={profile}
          setOpen={setOpen}
          parentCallback={handleCallback}
        />
      </div>
      <div className="successfull-password-popup">
        <SuccessfullPasswordPopup
          open={passwordopen}
          setOpen={setPasswordOpen}
        />
      </div>
    </div>
  );
};

export default Profile;
