import { combineReducers } from "redux";
import sideBarReducer from "../pages/common/reducer";
import profileReducer from "../pages/profile/reducer";
import paginationReducer from "../pages/user/reducer";
import villagerReducer from "../pages/villagers/reducer";
import notificationReducer from "../pages/notification/reducer";
//import regisReducer from '../pages/registration/reducer';

const rootReducer = combineReducers({
  sideBarReducer,
  profileReducer,
  paginationReducer,
  notificationReducer,
  //villagerReducer
});

export default rootReducer;
