import { React, useState } from "react";

import ConsentTableList from "./list";
import Button from "@mui/material/Button";


import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import TextField from "@mui/material/TextField";
import privateApi from "../../config/Private";
import CircularProgress from "@mui/material/CircularProgress";

import { DefaultEditor } from 'react-simple-wysiwyg';

var version = 0;

const Consent = () => {
  const [value, setInput] = useState({description:"",version:''});
  const [msg, setMsg] = useState({}); //
  const [loader, setLoader] = useState(false);
  const [updatedAt, setUpdatedAt] = useState("");



  const handleChange = (e) => {
    setInput((pre) => ({ ...pre, version: e.target.value }));
  };

  const handleDescription = (e) => {
    setInput((pre) => ({ ...pre, description:e }));
  };


  const handleCallback = (row) => {
     if (row.length > 0 ) {
        setInput({description:row[0]['description'],version:row[0]['version']});
        version  = row[0]['version'];
     }
  };




function onChange(e) {
    //setHtml(e.target.value);
    setInput((pre) => ({ ...pre, description:e.target.value }));
  }

  const handleSubmit = (e)=>{
     e.preventDefault();
     let errors ={};
     let  status = true;
     errors['description'] = "";
     errors['version'] = "";
     if (value.description == '' || value.description.trim() == '') {
         errors['consent_text'] = "Please fill the description in input box!";
         status = false;
     }
     if (value.version == '' || value.version.trim() == '') {
         errors['version'] = "Please fill the version number in input box!";
         status = false;
     }
     if ( version  == value.version ) {
       errors['version'] = "Please change the version number!";
       status = false;
     }

     if (status) {
       setLoader(true);
       privateApi
         .put("/cms/consent-update", value)
         .then((response) => {
            setUpdatedAt(response.data.responseData.consent.updatedAt);
            setLoader(false);
            setMsg((prev)=>({apiMsg:response.data.responseMsg}));
         })
         .catch((err) => {
           setLoader(false);
           setMsg((prev)=>({apiMsg:response.data.responseMsg}));
         });
     }
     setMsg(errors);
  }


  return (
    <div className="consent-wrapper">
      <div className="breadcrumb-wrap">
        <div className="breadcrumb-left">
          <div className="breadcrumb-left">
            <Link to="/consent-form">
              <h2 className="sub-title">Consent Management</h2>
            </Link>
            <div className="back-arrow">
              <KeyboardArrowRightIcon />
            </div>
              <h2 className="sub-title">Consent Form</h2>
          </div>
        </div>
      </div>
      <form  onSubmit={handleSubmit} >
      <div className="consent-text-editor">
        <div className="text-editor">
          <DefaultEditor value={value.description} onChange={onChange} />
        </div>
      </div>
      {msg.description && (
        <span className="error-msg">{msg.description}</span>
      )}
       <br></br>

      <div className="input-row">
        <label>Version Number </label>
        <TextField
          id="version"
          type="text"
          name="version"
          placeholder="Version number"
          onChange={(e) => handleChange(e)}
          value={value.version}
        />

      </div>
      {msg.version && (
        <span className="error-msg">{msg.version}</span>
      )}

      <div className="btn-sec">
        <div className="buttons">
          <Button variant="contained" type='submit'> {loader && <CircularProgress size={22} />} Update</Button>
        </div>
      </div>
      {
        msg.apiMsg && <span className="success-msg">{msg.apiMsg}</span>
      }
      </form>
      <div className="main-section-wrap">
        <div className="table-heading">
          <h2 className="sub-title">Update logs</h2>
        </div>
        <ConsentTableList parentCallback={handleCallback}  updatedAt={updatedAt}  />
      </div>
    </div>
  );
};

export default Consent;
