import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/login/Login";
import Logout from "../pages/login/Logout";
import Forgotpassword from "../pages/login/Forgotpassword";
import Resetpassword from "../pages/login/Resetpassword";
import ForgotResetPassword from "../pages/login/ForgotResetPassword";
import Profile from "../pages/profile/profile";
import Users from "../pages/user/Index";
import Villager from "../pages/villagers/Index";
import UsersDetail from "../pages/user/Detail/UsersDetail";
import VillageDetail from "../pages/villagers/detail/Detail";
import ContentUpdate from "../pages/content/ContentUpdate";
import Faq from "../pages/content/faq/Faq";
import Consent from "../pages/consent/Consent";
import CmsTab from "../pages/content/CmsTab";
import Private from "./Private";
import Public from "./Public";
import Notification from "../pages/notification/Notification";
import NotificationAdd from "../pages/notification/add/NotificationAdd";

const webpage = () => {
  return (
    <Router>
      <Switch>
        <Public exact component={Login} path="/" title="Admin Login" />
        <Public exact component={Login} path="/login" title="Admin Login" />
        <Public
          exact
          component={Forgotpassword}
          path="/forgot-password"
          title="Forgot password"
        />
        <Public
          exact
          component={Resetpassword}
          path="/reset-password"
          title="Reset password"
        />
        <Public
          exact
          component={ForgotResetPassword}
          path="/admin/reset-password/token/:token"
          title="Forgot password"
        />

        <Private exact component={CmsTab} path="/cms" title="CMS Management" />

        <Private
          exact
          component={ContentUpdate}
          path="/cms/:type"
          title="CMS Management"
        />

        <Private
          exact
          component={Dashboard}
          path="/dashboard"
          title="Dashboard"
        />
        <Private
          exact
          component={Profile}
          path="/profile"
          title="Profile Details"
        />
        <Private
          exact
          component={Users}
          path="/users"
          title="User Management"
        />
        <Private
          exact
          component={UsersDetail}
          path="/users/detail/:id"
          title="User Details"
        />
        <Private
          exact
          component={Villager}
          path="/villagers"
          title="Villager Management"
        />
        <Private
          exact
          component={VillageDetail}
          path="/village/detail/:id"
          title="Village Detail"
        />
        <Private
          exact
          component={Notification}
          path="/notification"
          title="Notification Management"
        />
        <Private
          exact
          component={NotificationAdd}
          path="/notification/add"
          title="Notification Management"
        />
        <Private
          exact
          component={Consent}
          path="/consent-form"
          title="Consent Management"
        />
        <Route exact path="/logout">
          <Logout />{" "}
        </Route>
        <Route
          path="*"
          component={() => {
            return <div>Not Found</div>;
          }}
        ></Route>
      </Switch>
    </Router>
  );
};

export default webpage;
