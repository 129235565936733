import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../assets/images/popup/img-10.png";
import TextField from "@mui/material/TextField";

import axiosPrivate from "../../config/Private";
import CircularProgress from "@mui/material/CircularProgress";
import { FormControl, MenuItem, Select } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddUser = (props) => {
  const { open, setOpen } = props;
  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState({
    name: "",
    email: "",
    susbcription: "",
  });
  console.log("value", value);
  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const [loader, setLoader] = React.useState(false);
  const changeStatus = (e) => {
    setLoader(true);
    axiosPrivate
      .post("/user/create", {
        email: value.email,
        name: value.name,
        userType: 1,
      })
      .then((response) => {
        setLoader(false);
        props.parentCallback(props.profileData.status);
        setOpen(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
    setOpen(false);
  };

  return (
    <div className="block-popup detail">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Add New User
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <div className="popup-body">
            <div className="add-user">
              <div className="input-row">
                <label>Full Name</label>
                <TextField
                  id="Name"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-row">
                <label>Email Address</label>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  required
                />
              </div>

              {/* <div className="input-row">
                <FormControl fullWidth>
                  <label>Subscription Duration</label>
                  <Select
                    id="subscription"
                    name="subscription"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value={0}>One Month</MenuItem>
                    <MenuItem value={2}>Six Month</MenuItem>
                    <MenuItem value={1}> One Year</MenuItem>
                    <MenuItem value={2}> Life Time</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
            </div>
          </div>
        </DialogContent>
        <div className="popup-footer">
          <div className="popup-footer-inner">
            <div className="btn-col">
              <div className="no-btn">
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
            <div className="btn-col">
              <Button
                variant="contained"
                onClick={changeStatus}
                color="primary"
              >
                {loader && <CircularProgress size={24} />}
                Add User
              </Button>
            </div>
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
};

export default AddUser;
