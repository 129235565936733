import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import FormDoc from "../../../assets/images/users-detail/form-doc.svg";
import "./Detail.css";
import { getDateInFormat } from "../../../utils/Profile";


const VillageDetailTableList = (props) => {
  let villagerMember = props.villageMember;

  let srNo = 0;
  return (
    <div className="table-wrapper village-detail-list">
      <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>Villager</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Village joined Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {villagerMember.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{++srNo}</TableCell>
                  <TableCell>
                    {row.memberAuthId.name}
                  </TableCell>
                  <TableCell>{row.memberAuthId.email}</TableCell>
                  <TableCell>{ getDateInFormat(row.joinDate) }</TableCell>
                </TableRow>
              ))}
              { villagerMember.length == 0 &&
                <TableRow key={1} align="center">
                  <TableCell colSpan={8}  >No Data found!</TableCell>
                </TableRow>
              }

            </TableBody>
          </Table>
        </TableContainer>
    </div>
  );
};

export default VillageDetailTableList;
