import { Link } from "react-router-dom";
import "./NotificationBreadcrumb.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const NotificationBreadcrumb = () => {
  return (
    <div className="breadcrumb-wrap">
      <div className="breadcrumb-left">
        <Link to="/notification">
          <h2 className="sub-title"> Notification Management</h2>
        </Link>
        <div className="back-arrow">
          <KeyboardArrowRightIcon />
        </div>
        <Link to="/notification/add">
          <h2 className="sub-title">Add Notification</h2>
        </Link>
      </div>
    </div>
  );
};

export default NotificationBreadcrumb;
