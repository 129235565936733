import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/header.css";

//import Notification from "../../assets/images/header/notification-icon.png";
import Profile from "../../assets/images/sidebar/user.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {  connect } from "react-redux";
import LogoutPopup from "../../components/logout/Logout";
import Adminimg from "../../assets/images/admin1.png";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function Header(props) {
  document.title =
    (props.title ? props.title : "Meerkat") + " | Meerkat Admin Panel";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  //const [isActive, setActiveClass] = React.useState(false);
  //const dispatch = useDispatch();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openLogoutBlock, setLogoutOpen] = React.useState(false);

  const handleLogoutBlockOpen = (e) => {
    e.preventDefault();
    setLogoutOpen(true);
  };

  /*const handleCollapseMenu = (e, i) => {
    if (i) {
      dispatch({ type: "SIDEBAR_COLLAPSE", payload: { status: false } });
      //setActiveClass(false);
    } else {
      dispatch({ type: "SIDEBAR_COLLAPSE", payload: { status: true } });
      //setActiveClass(true);
    }
  };*/
  return (
    <>
      <header className={"header" + (props.status ? " header-width" : "")}>
        <div className="header-inner">
          <div className="header-left ">
            <h1 className="title">{props.title ? props.title : "Dashboard"}</h1>
          </div>
          <div className="header-right">
            {/* <div className="notification-icon">
              <Tooltip title="Notification">
                <figure>
                  <img src={Notification} alt="Icon" />
                </figure>
              </Tooltip>
            </div> */}
            <div
              className="user-menu"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <div className="user-icon" style={{ "display":"none" }}>
                <figure>
                  <img
                    src={
                      Adminimg
                    }
                    alt="Icon"
                  />
                </figure>
              </div>
              <div className="user-name df">
                {localStorage.getItem("name")
                  ? localStorage.getItem("name")
                  : "Admin"}
                <span>
                  <ArrowDropDownIcon />
                </span>
              </div>
            </div>
            <div className="menu-wrap">
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <div className="menu-section active">
                    <Link to="/profile">
                      <figure>
                        <img className="profile1" src={Profile} alt="Icon" />
                      </figure>
                      <span>My Profile</span>
                    </Link>
                  </div>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <div className="menu-section">
                    <Link
                      onClick={(e) => handleLogoutBlockOpen(e)}
                      to="javascript:void(0)"
                    >
                      <figure>
                        <LogoutIcon />
                      </figure>
                      <span>Log out</span>
                    </Link>
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </header>
      <div className="block-alert">
        <LogoutPopup open={openLogoutBlock} setOpen={setLogoutOpen} />
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    status: state.sideBarReducer.status,
    profileDetails: state.profileReducer,
  };
}

export default connect(mapStateToProps)(Header);
