import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "../../assets/css/navbar.css";
import { connect } from "react-redux";

function Navbar(props) {
  const location = useLocation();
  const currentRoute = useParams();

  const [contentDropdown, setShowContentDrodown] = React.useState(false);

  const handleContentManagment = (e) => {
    e.preventDefault();
    setShowContentDrodown((contentDropdown) => !contentDropdown);
  };

  let pathsArray = [
    "/cms/about-us",
    "/cms/term-and-condition",
    "/cms/privacy-policy",
    "/cms"
  ];
  let path = pathsArray.includes(location.pathname);

  const closeContentMenu = (e) => {
    path = false;
    setShowContentDrodown(false);
  };

  let upDownIcon = contentDropdown || path ? "down-arrow" : "up-arrow";
  let isActive =
    contentDropdown || path ? "content-inactive" : "content-active";
  return (
    <aside className={"sidebar" + (props.status ? " sidebar-width" : "")}>
      <div className="sidebarInner">
        <div className="sidebar-header">
          <div className="sidebar-title">
            <h2>MEERKAT VILLAGE</h2>
          </div>
        </div>
        <div className="sidebar-main">
          <ul className="sidebar-menu">
            <li>
              <Link
                to="/dashboard"
                onClick={closeContentMenu}
                className={
                  location.pathname == "/dashboard"
                    ? " dashboard active"
                    : "dashboard"
                }
              >
                <i className="icon dashboard"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to="/users"
                className={
                  location.pathname == "/users" ||
                  location.pathname == "/users/detail/" + currentRoute.id
                    ? " user active"
                    : "user"
                }
                onClick={closeContentMenu}
              >
                <i className="icon user"></i>
                <span>User Management</span>
              </Link>
            </li>

            <li style={{ display: "" }}>
              <Link
                to="/villagers"
                className={
                  location.pathname == "/villagers" ||
                  location.pathname == "/village/detail/" + currentRoute.id
                    ? "active"
                    : ""
                }
                onClick={closeContentMenu}
              >
                <i className="icon village"></i>
                <span>Village Management</span>
              </Link>
            </li>

            <li style={{ display: "none" }}>
              <Link
                to="/notification"
                onClick={closeContentMenu}
                className={
                  location.pathname == "/notification"
                    ? " notification active"
                    : "notification"
                }
              >
                <i className="icon notification"></i>
                <span>Notification Management</span>
              </Link>
            </li>

            <li>
              <Link
                to="/consent-form"
                className={location.pathname == "/consent-form" ? "active" : ""}
                onClick={closeContentMenu}
              >
                <i className="icon consent"></i>
                <span>Consent Management</span>
              </Link>
            </li>
            <li className={"parent-menu" + upDownIcon} >
              <Link
              to="/cms"
                className={path ? " active" : ""}
              >
                <i className="icon content"></i>
                <span>CMS Management</span>
              </Link>
              </li>
            <li
              style={{ display: "none" }}
              className={"parent-menu  " + upDownIcon}
            >
              <Link
                to="#"
                className={path ? "arrow active" : "arrow"}
                onClick={(e) => handleContentManagment(e)}
              >
                <i className="icon content"></i>
                <span>CMS Management</span>
              </Link>
              <ul className={"sub-menu " + isActive}>
                <li>
                  <Link
                    to="/content/privacy-policy"
                    className={
                      location.pathname == "/content/privacy-policy"
                        ? "active"
                        : ""
                    }
                  >
                    <i className="icon privacy-policy"></i>
                    <span>Privacy Policy</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/content/terms-conditions"
                    className={
                      location.pathname == "/content/terms-conditions"
                        ? "active"
                        : ""
                    }
                  >
                    <i className="icon terms-conditions"></i>
                    <span>Terms & Condition</span>
                  </Link>
                </li>

              </ul>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
}

function mapStateToProps(state) {
  return {
    status: state.sideBarReducer.status,
    profileDetails: state.profileReducer,
  };
}

export default connect(mapStateToProps)(Navbar);
