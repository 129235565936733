import React from "react";

import { Redirect, Route } from "react-router-dom";
import Layouts from "../pages/common/Layouts";

function Private({ component: Component, ...rest }) {
  let authorizations = localStorage.getItem("authStatus") == "1" ? 1 : 0;
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        authorizations ? (
          <Layouts {...rest}>
            <Component {...props} />
          </Layouts>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default Private;
