export const getDateInFormat = (date) => {
  if (date) {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }
  return "N/A";
};

export const getStatusText = (status) => {
  switch (status) {
    case 0:
      return "In Progress";
      break;
    case 1:
      return "Active";
      break;
    case 2:
      return "Blocked";
      break;
    case 3:
      return "Deleted";
      break;
    default:
  }
};

export const getThemeColor = (colorNo) => {
  switch (colorNo) {
    case 1:
      return "Orange";
      break;
    case 2:
      return "Red";
      break;
    case 3:
      return "Yellow";
      break;
    case 4:
      return "Purple";
      break;
    default:
      return "N/A";
  }
};

export const getUserType = (type) => {
  switch (type) {
    case 1:
      return "Village Owner";
      break;
    case 2:
      return "Villager";
      break;
    default:
      return "N/A";
  }
};

export const plateformType = (plateformType) => {
  if (plateformType.length == 2) {
    return "All";
  }
  if (plateformType.length == 1 && plateformType[0] == 1) {
    return "Android";
  }
  if (plateformType.length == 1 && plateformType[0] == 2) {
    return "IOS";
  }
  return "N/A";
};
