import { React, useState,useEffect } from "react";

import Button from "@mui/material/Button";


import { Link,useParams } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import TextField from "@mui/material/TextField";
import privateApi from "../../config/Private";
import CircularProgress from "@mui/material/CircularProgress";

import { DefaultEditor } from 'react-simple-wysiwyg';



var version = 0;

const ContentUpdate = () => {
  const [value, setInput] = useState({description:"",type:"",title:""});
  const [msg, setMsg] = useState({}); //
  const [loader, setLoader] = useState(false);

  var param = useParams();

  const handleDescription = (e) => {
    setInput((pre) => ({ ...pre, description:e }));
  };


function onChange(e) {
    //setHtml(e.target.value);
    setInput((pre) => ({ ...pre, description:e.target.value }));
  }

  const handleSubmit = (e)=>{
     e.preventDefault();
     let errors ={};
     let  status = true;
     errors['description'] = "";
     if (value.description == '' || value.description.trim() == '') {
         errors['description'] = "Please fill the description in input box!";
         status = false;
     }

     if (status) {
       setLoader(true);
       privateApi
         .put("/cms/cms-update", {type:param.type,description:value.description})
         .then((response) => {
            setLoader(false);
            setMsg((prev)=>({apiMsg:response.data.responseMsg}));
         })
         .catch((err) => {
           setLoader(false);
           setMsg((prev)=>({apiMsg:response.data.responseMsg}));
         });
     }else{
       setMsg(errors);
     }

  }

useEffect(() => {
  setLoader(true);
    privateApi
      .get("/cms/detail?type="+param.type)
      .then((response) => {
          setInput({description:response.data.responseData.cms.description,type:response.data.responseData.cms.type,title:response.data.responseData.cms.title});
          setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, []);


  return (
    <>
    {loader ? <div
          className="content-loader"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress size={100} />
        </div> : <div className="consent-wrapper">
      <div className="breadcrumb-wrap">
        <div className="breadcrumb-left">
          <div className="breadcrumb-left">
            <Link to="/cms">
              <h2 className="sub-title">CMS</h2>
            </Link>
            <div className="back-arrow">
              <KeyboardArrowRightIcon />
            </div>
              <h2 className="sub-title">{value.title}</h2>
          </div>
        </div>
      </div>
      <form  onSubmit={handleSubmit} >
      <div className="consent-text-editor">
        <div className="text-editor">
          <DefaultEditor value={value.description} onChange={onChange} />
        </div>
      </div>
      {msg.description && (
        <span className="error-msg">{msg.description}</span>
      )}
       <br></br>
      <div className="btn-sec">
        <div className="buttons">
          <Button variant="contained" type='submit'> {loader && <CircularProgress size={22} />} Update</Button>
        </div>
      </div>
      {
        msg.apiMsg && <span className="success-msg">{msg.apiMsg}</span>
      }
      </form>
    </div>}
    </>
  );
};

export default ContentUpdate;
