import React, { useState, useEffect } from "react";
import NotificationBreadcrumb from "../../../components/breadcrumbs/notification-breadcrumb/NotificationBreadcrumb";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import SuccessfullPopup from "../../../components/notification/successfull-popup/SuccessfullPopup";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import "./NotificationAdd.css";
import CircularProgress from "@mui/material/CircularProgress";
import axiosPrivateApi from "../../../config/Private";

var checkboxCheckedCount = [];
const NotifcationAdd = () => {
  const [openSuccess, setSuccessOpen] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  var plateformTypeAll = [];
  var formFields = {
    inputs: {
      title: "",
      description: "",
      platform: [],
      sendTo: [],
      tokens:[]
    },
    sendTo: [],
    checkboxes: [],
    errors: {},
  };

  const [state, setFormInput] = useState(formFields);


  const handleSuccessClickOpen = () => {
    setSuccessOpen(true);
  };

  const [loader, setLoader] = useState(false);
  const [loaderSubmit, setLoaderSubmit] = useState(false);

  const [userList, setUserList] = useState([]);

  useEffect( () => {
    async function getList(){
    axiosPrivateApi
      .get("/admin/users")
      .then((res) => {
        if (res.data.statusCode == 200) {
          setUserList(res.data.data.users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
    getList();
  }, []);

  const handleChange = (e) => {
    let input = state.inputs;
    input[e.target.name] = e.target.value;
    setFormInput((prevState) => ({ ...prevState, inputs: input }));
  };

  const handleCheckBoxChange = (e, value) => {
    if (!state.checkboxes.includes(e.target.value) && e.target.checked) {
      checkboxCheckedCount.push(e.target.value);
      if (e.target.value == 3) {
        plateformTypeAll = [1, 2];
      }
      setFormInput((prev) => ({
        ...prev,
        inputs: {
          ...prev.inputs,
          platform: [...prev.inputs.platform, parseInt(e.target.value)],
        },
        checkboxes: [...prev.checkboxes, e.target.value],
      }));
    } else {
      checkboxCheckedCount.pop();
      if (e.target.value == 3) {
        plateformTypeAll = [];
      }
      setFormInput((prev) => ({
        ...prev,
        inputs: {
          ...prev.inputs,
          platform: [
            ...prev.inputs.platform.filter(
              (val) => val != parseInt(e.target.value)
            ),
          ],
        },
        checkboxes: prev.checkboxes.filter((val) => val !== e.target.value),
      })); // val !== e.target.value
    }
  };

  const handleSubmit = (e, status) => {
    e.preventDefault();
    if (validateForm(e)) {
      if (!status) {
        setLoaderSubmit(true);
      } else {
        setLoader(true);
      }
      state.inputs.isDraft = status ? true : false;
      axiosPrivateApi
        .post("/admin/notification", state.inputs)
        .then((response) => {
          if (!status) {
            setLoaderSubmit(false);
          } else {
            setLoader(false);
          }
          if (response.data.statusCode == 201) {
            handleSuccessClickOpen();
          }
        })
        .catch((err) => {
          setLoader(false);
          setErrMsg(err.response.data.message);
          console.log("err", err.response.data);
          //setShowError(err.response.data.message);
        });
    }
  };

  useEffect(()=>{
    //console.log(state);
  },[state]);

  const validateForm = (e) => {
    let errors = {};
    errors["title"] = "";
    errors["description"] = "";
    errors["userList"] = "";
    errors["plateform"] = "";
    errors["userLists"] = "";
    let isValid = true;
    if (state.inputs.title == undefined || state.inputs.title == "") {
      isValid = false;
      errors["title"] = "Please enter the title.";
    }
    if (
      state.inputs.description == undefined ||
      state.inputs.description == ""
    ) {
      isValid = false;
      errors["description"] = "Please enter the description.";
    }
    if (state.inputs.platform.length == 0 && state.sendTo.length == 0 ) {
      isValid = false;
      errors["plateform"] = "Please select the plateform type.";
    }
    if (state.inputs.sendTo.length == 0 && checkboxCheckedCount.length == 0) {
      isValid = false;
      errors["userListErr"] = "Please select the users from listing.";
    }
    setFormInput((prevState) => ({ ...prevState, errors: errors }));
    return isValid;
  };

  return (
    <div className="notification-add-wrap">
      <NotificationBreadcrumb />
      <div className="notification-title">
        <h2 className="sub-title">Details</h2>
      </div>
      <div className="notification-add-wrap">
        <form>
          <div className="input-field">
            <label className="form-label">*Title</label>
            <TextField
              type="text"
              placeholder="Please enter the title"
              onChange={handleChange}
              name="title"
              value={state.inputs.title}
            />
            {state.errors.title && (
              <span className="error-msg">{state.errors.title}</span>
            )}
          </div>
          <div className="input-field">
            <label className="form-label">*Description</label>
            <TextField
              type="text"
              placeholder="Type Description here"
              onChange={handleChange}
              name="description"
              value={state.inputs.description}
              multiline
              rows={4}
            />
            {state.errors.description && (
              <span className="error-msg">{state.errors.description}</span>
            )}
          </div>
          <div className="input-field">
            <label className="form-label">*Platform Type</label>
            <div className="checkbox-wrap">
              <div className="checkbox-content">
                <Checkbox
                  onChange={(e) => handleCheckBoxChange(e, 1)}
                  name="platform"
                  value={1}
                  disabled={state.sendTo.length > 0 ? true : false }
                />
                <p>Android</p>
              </div>
              <div className="checkbox-content">
                <Checkbox
                  onChange={(e) => handleCheckBoxChange(e, 2)}
                  name="platform"
                  value={2}
                  disabled={state.sendTo.length > 0 ? true : false }
                />
                <p>IOS</p>
              </div>
              {/*<div className="checkbox-content">
              <Checkbox onChange={(e)=>handleCheckBoxChange(e,3)} name='platform' value={3}  />
              <p>All</p>
            </div>*/}
            </div>
            {state.errors.plateform && (
              <span className="error-msg">{state.errors.plateform}</span>
            )}
          </div>
          <div className="input-field">
            <label className="form-label">Select User</label>
            <div className="slect-user-body">
              <Stack>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  name="sendTo"
                  disabled={checkboxCheckedCount.length > 0 ? true : false}
                  options={userList}
                  getOptionLabel={(option) =>
                    option.FirstName + " " + option.LastName
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.FirstName == value.FirstName
                  }
                  onChange={(event, newValue) => {
                    setFormInput((prev) => ({
                      ...prev,
                      inputs: {
                        ...prev.inputs,
                        sendTo: newValue.map((v) => v.Id),
                        tokens:newValue.map((v) => v.DeviceToken),
                      },
                      sendTo: newValue.map((v) => v.Id),
                    }));

                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Please select the users"
                    />
                  )}
                />
              </Stack>
              {state.errors.userListErr && (
                <span className="error-msg">{state.errors.userListErr}</span>
              )}
            </div>
          </div>
          {errMsg ? <span className="error-msg">{errMsg}</span> : ""}

          <div className="btn-section">
            <Button
              variant="outlined"
              onClick={(e) => handleSubmit(e, 1)}
              color="primary"
            >
              {loader && <CircularProgress size={22} />}
              Save as draft
            </Button>
            <Button variant="contained" onClick={(e) => handleSubmit(e, 0)}>
              {loaderSubmit && <CircularProgress size={22} />}
              Submit
            </Button>
          </div>
        </form>
      </div>
      <div className="successfull-alert">
        <SuccessfullPopup open={openSuccess} setOpen={setSuccessOpen} />
      </div>
    </div>
  );
};

export default NotifcationAdd;
