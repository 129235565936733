import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
//import ColumnSort from "../../../components/columnsort/Columnsort";
import { Link } from "react-router-dom";
import UserIcon from "../../../assets/images/users/user-icon.png";
import DeletePopup from "../../../components/deletepopup/deletepopup";
import BlockPopup from "../../../components/blockpopup/blockpopup";
import UnblockPopup from "../../../components/unblockpopup/unblockpopup";
import "./list.css";
import axiosPrivate from "../../../config/Private";
import {
  getStatusText,
  getDateInFormat,
  getUserType,
} from "../../../utils/Profile";
import { useDispatch } from "react-redux";
import Loader from "../../../components/loader/loader";
import Button from "@mui/material/Button";

const UserTableList = (props) => {
  const [openDelete, setDeleteOpen] = useState(false);
  const [userListing, setUserList] = useState([]);
  const [changeStatus, setUpdateStatus] = useState("");

  const [processing, setProcessing] = useState(false);

  const [profile, setProfileData] = useState([]);

  const dispatch = useDispatch();

  const handleDeleteClickOpen = (e, data) => {
    setProfileData(data);
    setDeleteOpen(true);
  };
  var filterInput = props.data.input;
  var filterParam = {
    limit: filterInput.limit,
    offset: props.offset ? (props.offset - 1) * 10 : props.offset,
  };
  if (filterInput.search != "") {
    filterParam.search = filterInput.search;
  }

  if (filterInput.status) {
    filterParam.userType = filterInput.status;
  }
  filterParam.sortBy = "_id";
  filterParam.sortOrder = "desc";

  var lastOffset = props.offset;

  var srNo =
    0 + (lastOffset ? (lastOffset - 1) * props.data.input.limit : lastOffset);

  const [openBlock, setBlockOpen] = useState(false);
  const handleBlockClickOpen = (e, data) => {
    setProfileData(data);
    setBlockOpen(true);
  };

  const [openUnblock, setUnblockOpen] = useState(false);
  const handleUnblockClickOpen = (e, data) => {
    setProfileData(data);
    setUnblockOpen(true);
  };

  const updateStatus = (childData) => {
    setUpdateStatus(childData);
    setBlockOpen(false);
  };

  useEffect(() => {
    if (props.data.isSubmited || changeStatus) {
      async function getUserList() {
        setProcessing(true);
        axiosPrivate
          .post("/user/list", filterParam)
          .then((res) => {
            setProcessing(false);
            if (res.data.responseCode == 200) {
              setUserList(res.data.responseData.list);
              dispatch({
                type: "PAGINATION",
                payload: { total: res.data.responseData.count, per_page: 10 },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      getUserList();
    }
  }, [props, changeStatus]);
  console.log("usrlist", userListing);

  return (
    <div className="user-main-wrapper">
      <div className="table-wrapper user-list">
        {processing && <Loader />}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>
                  <div className="sort-outer">
                    <span>Name</span>
                    {/*<ColumnSort />*/}
                  </div>
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>User Type</TableCell>
                <TableCell>Account Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>
                  <div className="sort-outer">
                    <span>Reg On</span>
                    {/*<ColumnSort />*/}
                  </div>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userListing.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{++srNo}</TableCell>
                  <TableCell>
                    <div className="user-info-sec">
                      <div className="user-image">
                        <figure>
                          <img
                            src={row.avatarUrl ? row.avatarUrl : UserIcon}
                            alt="Image"
                          />
                        </figure>
                      </div>
                      <Link to={"users/detail/" + row?.authId?._id}>
                        {row.name}
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{getUserType(row.userType)}</TableCell>
                  <TableCell>
                    {row.isFreeSubscription ? "Free" : "Paid"}
                  </TableCell>
                  <TableCell>
                    <div className="table-btn">
                      <div
                        className={row?.authId?.status == 1 ? "d-block" : ""}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          color={row?.authId?.status == 1 ? "success" : "error"}
                        >
                          {getStatusText(row?.authId?.status)}
                        </Button>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>{getDateInFormat(row.createdAt)}</TableCell>
                  <TableCell>
                    <div className="action-wrap">
                      <Tooltip title="Delete">
                        <div onClick={(e) => handleDeleteClickOpen(e, row)}>
                          <IconButton color="error">
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </Tooltip>

                      {row?.authId?.status == 1 && (
                        <Tooltip title="Block">
                          <div onClick={(e) => handleBlockClickOpen(e, row)}>
                            <IconButton color="error">
                              <BlockIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      )}
                      {row?.authId?.status == 2 && (
                        <Tooltip title="Unblock">
                          <div onClick={(e) => handleUnblockClickOpen(e, row)}>
                            <IconButton>
                              <CheckCircleOutlineIcon color="success" />
                            </IconButton>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {userListing?.length == 0 && (
                <TableRow>
                  <TableCell colSpan="7">No data found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="unblock-alert">
        <DeletePopup
          open={openDelete}
          profileData={profile}
          parentCallback={updateStatus}
          setOpen={setDeleteOpen}
        />
      </div>
      <div className="block-alert">
        <BlockPopup
          profileData={profile}
          parentCallback={updateStatus}
          open={openBlock}
          setOpen={setBlockOpen}
        />
      </div>
      <div className="unblock-alert">
        <UnblockPopup
          profileData={profile}
          open={openUnblock}
          parentCallback={updateStatus}
          setOpen={setUnblockOpen}
        />
      </div>
    </div>
  );
};

export default UserTableList;
