import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from '@mui/material/Stack';
import { useSelector,useDispatch } from 'react-redux'
import "./pagination.css";

const PaginationSection = (props) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
      setPage(value);
      props.parentCallback(value);
      dispatch({ type: "NEXT_PAGINATION", payload: {"page_no":value} });
    };
    var  paginationCount = 0;
    let paginationReducer = useSelector( (state) => state.paginationReducer);
    if (paginationReducer.page === "villager") {
        paginationCount = paginationReducer.total/paginationReducer.per_page;
    }else{
        paginationCount = paginationReducer.total/paginationReducer.per_page;
    }
    paginationCount = Math.ceil(paginationCount);
  return (
    <div className="pagination-wrap">
      <div className="pagination">
        <Stack spacing={2}>
          <Pagination count={paginationCount || 0} page={page} onChange={handleChange} />
        </Stack>
      </div>

    </div>
  );
};

export default PaginationSection;
