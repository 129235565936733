import React,{useEffect,useState} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Consent.css";
import privateApi from "../../config/Private";
import { getDateInFormat } from "../../utils/Profile";

const ConsentTableList = (props) => {

  const [list,setList] = useState([]);
  console.log("update=>",props.updatedAt);
  useEffect(() => {
    privateApi
      .get("/cms/consent-list")
      .then((response) => {
          setList(response.data.responseData.consent);
          let activeRow = response.data.responseData.consent.filter(data=>data.status <= 1);
          props.parentCallback(activeRow);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.updatedAt]);

  let srNo = 0;



  return (
    <div className="table-wrapper consent-list">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>S.No.</TableCell>
              <TableCell>Village Name</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Last updatd on</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{++srNo}</TableCell>
                <TableCell>  <div dangerouslySetInnerHTML={{__html: row.description}} /> </TableCell>
                <TableCell>{row.version}</TableCell>
                <TableCell>{getDateInFormat(row.updatedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ConsentTableList;
