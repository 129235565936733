import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import VillageUserTableList from "./list/list";
import PaginationSection from "../../components/pagination/pagination";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
const Villager = () => {
  var defaultInput = {
    input: { search: "", limit: 10 },
    isSubmited: true,
  };
  const [selectPerPage, setSelectPerPage] = useState(10);
  const [offset, setSetOffset] = useState(0);
  const [inputValues, setSetInputValues] = useState(defaultInput);
  const [user, setUser] = useState(1);

  const handleCallback = (childData) => {
    //setUserList(childData);
    setSetOffset(childData);
  };
  const dispatch = useDispatch();
  const handleChange = (e) => {
    let input = inputValues.input;
    input[e.target.name] = e.target.value;
    setSetInputValues({ input: input, isSubmited: false });

    if (e.target.name == "limit") {
      setSelectPerPage(e.target.value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSetInputValues((prev) => ({ ...prev, isSubmited: true }));
    setSetOffset(0);
    dispatch({
      type: "VILLAGER_PAGINATION",
      payload: { per_page: inputValues.input.limit, page: "villager" },
    });
  };

  const handleUserCallback = (childData) => {};

  const reset = () => {
    setSetInputValues(defaultInput);
  };
  return (
    <div className="user-wrap">
      <div className="filter-area">
        <form onSubmit={handleSubmit}>
          <div className="filter-top-content">
            <div className="search-box">
              <TextField
                type="text"
                name="search"
                onChange={handleChange}
                value={inputValues.input.search}
                placeholder="Search by Village Name"
              />
            </div>
            <div className="select-box">
              <FormControl fullWidth>
                <Select
                  value={inputValues.input.limit}
                  onChange={handleChange}
                  name="limit"
                  displayEmpty
                >
                  <MenuItem value={10}>Show 10</MenuItem>
                  <MenuItem value={50}>Show 50</MenuItem>
                  <MenuItem value={20}>Show 20</MenuItem>
                  <MenuItem value={100}>Show 100</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="btn-area">
            <Button variant="contained" type="submit">
              Apply
            </Button>
            <div className="reset-btn">
              <Button variant="outlined" color="error" onClick={reset}>
                Reset
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className="main-section-wrap">
        <VillageUserTableList
          data={inputValues}
          parentUserCallback={handleUserCallback}
          type="onsubmit"
          page="villageList"
          offset={offset}
        />
        <PaginationSection
          parentCallback={handleCallback}
          page="villageList"
          data={offset}
        />
      </div>
    </div>
  );
};

export default Villager;
