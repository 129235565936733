import React, { useState } from "react";
import "../../assets/css/login.css";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import axiosPublic from "../../config/public";
import Logo from "../../assets/images/login/Logo.svg";

function Forgotpassword() {
  const states = {
    input: {},
    errors: {},
  };
  const [state, setState] = useState(states);
  const [msg, setMsg] = useState({"msg":'','status':true});
  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validater(e)) {
      let email = state.input.email;
      setLoader(true);
      axiosPublic
        .post("/recover-password", { email: email })
        .then((response) => {
          setLoader(false);
          setState((pre) => ({ ...pre, errors: {} }));
          setMsg({"msg":response.data.message,'status':true});
        })
        .catch((err) => {
          setLoader(false);
          setState((pre) => ({ ...pre, errors: {} }));
          setMsg({"msg":err.response.data.message,'status':false});
        });
    }
  };

  const handleChange = (e) => {
    let input = state.input;
    input[e.target.name] = e.target.value;
    setState((pre) => ({ ...pre, input }));
  };

  const validater = (e) => {
    let isValid = true;
    let errors = {};
    errors["email"] = "";
    let input = state.input;
    if (input.email == undefined || input.email == "") {
      isValid = false;
      setMsg({"msg":'','status':true});
      errors["email"] = "Please enter your email address.";
      setState((pre) => ({ ...pre, errors: errors }));
    }
    return isValid;
  };
  return (
    <div className="account-wrapper">
      <div className="account-inner">
        <div className="login-section">
          <div className="logo">
            <Link to="/">
              <figure>
                <img src={Logo} alt="Logo" />
              </figure>
            </Link>
          </div>
          <h1 className="page-title">Forgot Your Password</h1>
          <div className="form-wrapper top-spacing">
            <form onSubmit={handleSubmit}>
              <div className="input-row">
                <label>Email Address</label>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  onChange={(e) => handleChange(e)}
                  value={states.input.email}
                />
                <span className="error-msg">{state.errors.email}</span>
                {msg.status ? <span className="success-msg">{msg.msg}</span> : ""}
                {!msg.status ? <span className="error-msg">{msg.msg}</span> : ""}
              </div>

              <div className="btn-wrap">
                <Button variant="contained" type="submit" disabled={loader}>
                  {loader && <CircularProgress size={24} />}
                  Send Email
                </Button>
                <div className="back-login-btn">
                  <Link to="/login"> Back to Login</Link>
                  {/* <Button variant="contained">
                    <Link to="/login"> Back to Login</Link>
                  </Button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgotpassword;
